/* eslint-disable */

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

if (getCookie('wpl_viewed_cookie') == 'yes') {
  if (JSON.parse(getCookie('wpl_user_preference')).analytics == "yes") {
    enableGoogleAnalytics();
  }
}

window.addEventListener('GdprCookieConsentOnAccept', e => {
  console.log(e);
  if (e.detail.wpl_viewed_cookie == 'yes') {
    if (e.detail.wpl_user_preference.analytics == 'yes') {
      enableGoogleAnalytics();
    }
  }
}, false);


function enableGoogleAnalytics() { 
  console.log('analytics');

  var s = document.createElement('script');
  s.type = "text/javascript"
  s.async = "true";
  s.src = "https://www.googletagmanager.com/gtag/js?id=UA-170074541-2"; // Add tracking code here
  var x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-170074541-2'); // Add tracking code here
}

// checked analytics checkbox
document.getElementById("gdpr_messagebar_body_button_analytics").checked = true;